import React from "react";

function PrevIcon() {
	return (
		<div   className="prevIcon">
			<svg
				id="Group_1428"
				data-name="Group 1428"
				xmlns="http://www.w3.org/2000/svg"
				width="21.599"
				height="36.765"
				viewBox="0 0 21.599 36.765"
              
			>
				<path
					id="Path_1317"
					data-name="Path 1317"
					d="M102.064,16.948,118.425.587a2.015,2.015,0,0,1,2.844,0l1.2,1.2a2.014,2.014,0,0,1,0,2.844L108.736,18.375,122.49,32.128a2.016,2.016,0,0,1,0,2.845l-1.2,1.2a2.015,2.015,0,0,1-2.844,0L102.064,19.8a2.03,2.03,0,0,1,0-2.854Z"
					transform="translate(-101.478 0)"
					fill="#557FC0"
				/>
			</svg>
		</div>
	);
}

export default PrevIcon;
